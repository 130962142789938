<template>
  <a-modal
    :visible="showModal"
    :closable="false"
    title="Filter"
  >
    <a-form-model ref="formRef" :model="form" class="export-inventory">
      <a-form-model-item label="Bisnis">
        <a-select
          v-model="form.business"
          mode="multiple"
          class="w-100"
          placeholder="Pilih Bisnis"
          show-search
          allow-clear
          :options="businessList"
          option-filter-prop="children"
          :filter-option="filterOption"
          @deselect="onDeselectBusiness"
          @change="onChangeValue($event, 'business')"
        />
      </a-form-model-item>
      <a-form-model-item label="Channel">
        <a-select
          v-model="form.channel"
          mode="multiple"
          class="w-100"
          placeholder="Pilih Channel"
          show-search
          allow-clear
          option-filter-prop="children"
          :filter-option="filterOption"
          :not-found-content="isFetching ? undefined : null"
          :disabled="!form.business.length"
          @focus="onFocusList"
          @change="onChangeValue($event, 'channel')"
        >
          <a-spin v-if="isFetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="channel in channelList" :key="channel.value">
            <div v-if="channel.value === 'all'">
              {{ channel.label }}
            </div>
            <div v-else class="d-flex align-items-center justify-content-between">
              <span class="mr-2 w-75 text-truncate">{{ channel.label }}</span>
              <a-tag>{{ channel.channelName }}</a-tag>
            </div>
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Gudang">
        <a-select
          v-model="form.warehouse"
          mode="multiple"
          class="w-100"
          placeholder="Pilih Gudang"
          show-search
          allow-clear
          option-filter-prop="children"
          :filter-option="filterOption"
          :not-found-content="isFetching ? undefined : null"
          :disabled="!form.business.length"
          @focus="onFocusList"
          @change="onChangeValue($event, 'warehouse')"
        >
          <a-spin v-if="isFetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="warehouse in warehouseList" :key="warehouse.value">
            {{ warehouse.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('order.ordersPeriod')">
        <a-range-picker
          v-model="form.date"
          class="w-100"
          :disabled-date="disabledDate"
          :placeholder="[$t('utils.choose') + ' ' + $t('order.ordersPeriodFrom'), $t('utils.choose') + ' ' + $t('order.ordersPeriodUntil')]"
          :allow-clear="false"
          :get-calendar-container="() => $refs.formRef.$el"
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          @change="onChangeDate"
        >
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
      </a-form-model-item>
    </a-form-model>
    <template #footer>
      <div class="d-flex">
        <a-button @click="resetForm">
          {{ $t('order.cleanFilter') }}
        </a-button>
        <a-button class="ml-auto" @click="$emit('close')">
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button type="primary" class="ml-2" @click="submitFilter">
          {{ $t('utils.apply') }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { getChannelsByBusinessList } from '@/api/business'
import { getWarehouseList } from '@/api/warehouse'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'

export default {
  name: 'ModalExportInventory',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    businessValue: {
      type: Array,
      default: () => [],
    },
    channelValue: {
      type: Array,
      default: () => [],
    },
    warehouseValue: {
      type: Array,
      default: () => [],
    },
    customerValue: {
      type: String,
      default: '',
    },
    courierValue: {
      type: String,
      default: '',
    },
    orderPeriod: {
      type: Array,
      default: () => [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()],
    },
  },
  data() {
    return {
      isFetching: false,
      form: {
        business: [],
        channel: [],
        warehouse: [],
        date: [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()],
      },
      channelList: [],
      warehouseList: [],
      currentBusinessId: [],
      currentChannelList: [],
      currentWarehouseList: [],
      originalChannelList: [],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    businessList() {
      let list = [{ value: 'all', label: 'Semua Bisnis' }]
      if (!this.form.business.includes('all')) {
        this.$store.state.user.businessList?.forEach(business => {
          if (!!this.businessId) {
            if (this.businessId === business.business_id) {
              list.push({ value: business.business_id, label: business.business_name })
            }
          } else {
            if (business.business_id !== 'all') {
              list.push({ value: business.business_id, label: business.business_name })
            }
          }
        })
      }

      return list
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        let finalDate = this.orderPeriod
        if (finalDate.includes('all')) finalDate = undefined
        this.form = {
          business: this.businessValue,
          channel: this.channelValue,
          warehouse: this.warehouseValue,
          customer: this.customerValue,
          courier: this.courierValue,
          date: finalDate,
        }
        this.onFocusList()
      } else {
        this.resetForm()
      }
    },
  },
  methods: {
    fetchList(arr) {
      arr.forEach(async type => {
        const listName = type === 'channel' ? 'channelList' : 'warehouseList'
        const currentName = type === 'channel' ? 'currentChannelList' : 'currentWarehouseList'
        const list = this.filterBusinessList()
        const params = {
          business_id: this.getBusinessId(list),
          status: type === 'channel' ? 'CONNECTED' : undefined,
        }
        const promise = type === 'channel' ? [getChannelsByBusinessList(params)] : [getWarehouseList(params)]

        await Promise.all(promise)
        .then(([{ data }]) => {
          if (type === 'channel') this.originalChannelList = data
          const response = type === 'channel' ? data : data?.data
          const label = type === 'channel' ? 'Channel' : 'Gudang'
          const formName = type
          const all = [{ value: 'all', label: `Semua ${label}` }]

          let defaultList = []
          response?.forEach(res => {
            defaultList.push({
              value: res.id.toString(),
              label: type === 'channel' ? (res?.channel_title || res?.channelTitle) : res?.name,
              channelId: type === 'channel' ? (res?.sales_channel_id || res?.salesChannelId) : null,
              channelCode: type === 'channel' ? (res?.sales_channel_code || res?.salesChannelCode) : null,
              channelName: type === 'channel' ? (res?.sales_channel_name || res?.salesChannelName) : null,
            })
          })
          defaultList = defaultList.length ? orderBy(defaultList, ['label'], ['asc']) : defaultList

          const list = all.concat(defaultList)
          this[listName] = !this.form[formName].length || (this.form[formName].length && !this.form[formName].includes('all'))
            ? list
            : all
          this[currentName] = list

          if (!!type && !!this.$route.query[formName]) this.form[formName] = this.$route.query[formName].split(',')
          if (this.form[formName].length && !this.form[formName].includes('all')) {
            this.form[formName] = this.form[formName].filter(item => {
              const itemIndex = list.findIndex(i => i.value === item)
              return itemIndex >= 0
            })
          }

          this.$emit('onSetList', { type, list })
        })
        .catch(err => {
          console.error(err)
          this[listName] = []
          this[currentName] = []
          if (type === 'channel') this.originalChannelList = []
          this.$emit('onSetList', { type, list: [] })
        })
        .finally(() => this.isFetching = false)
      })
    },
    onChangeValue(value, type) {
      this.form[type] = value.includes('all')
        ? ['all']
        : value

      if (type === 'business' && !value.length) {
        this.form.channel = []
        this.channelList = []
        this.currentChannelList = []
        this.form.warehouse = []
        this.warehouseList = []
        this.currentWarehouseList = []
      } else {
        const listName = type === 'channel' ? 'channelList': 'warehouseList'
        const label = type === 'channel' ? 'Channel' : 'Warehouse'
        const currentName = type === 'channel' ? 'currentChannelList' : 'currentWarehouseList'
        this[listName] = value.includes('all')
          ? [{ value: 'all', label: `Semua ${label}` }]
          : this[currentName]
      }
    },
    onDeselectBusiness(value) {
      this.form.business = this.form.business.length
        ? this.form.business.filter(business => business !== value)
        : []
      this.onFocusList()
    },
    onFocusList() {
      this.isFetching = true
      const isSame = isEqual(this.currentBusinessId, this.form.business)
      if (!isSame) {
        this.channelList = []
        this.currentChannelList = []
        this.warehouseList = []
        this.currentWarehouseList = []
        this.currentBusinessId = this.form.business

        if (this.form.business.length) {
          this.fetchList(['channel', 'warehouse'])
        } else {
          this.isFetching = false
        }
      } else {
        this.isFetching = false
      }
    },
    getBusinessId(list) {
      return this.form.business.length && this.form.business.includes('all')
        ? list.join()
        : this.form.business.length && !this.form.business.includes('all')
        ? this.form.business.join()
        : undefined
    },
    filterBusinessList() {
      let list = []
      if (this.form.business.length && this.form.business.includes('all')) {
        this.$store.state.user.businessList?.forEach(business => {
          if (!!this.businessId) {
            if (this.businessId === business.business_id) {
              list.push(business.business_id)
            }
          } else {
            if (business.business_id !== 'all') {
              list.push(business.business_id)
            }
          }
        })
      }

      return list
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0]?.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    disabledDate (current) {
      return current && current < this.$moment().subtract(91, 'day')
    },
    onChangeDate(date) {
      this.form.date = [this.$moment(date[0]).startOf('day').format(), this.$moment(date[1]).endOf('day').format()]
    },
    submitFilter() {
      const { business, channel, warehouse, date } = this.form
      let sales_channel = []
      this.form.channel.forEach(ch => {
        const findChannel = this.originalChannelList.find(c => c.id == ch)
        if (findChannel?.id) sales_channel.push(findChannel?.sales_channel_id || findChannel?.salesChannelId)
      })
      this.$emit('submit', {
        ...this.form,
        business: !business.includes('all') ? business : [],
        channel: !channel.includes('all') ? channel : [],
        warehouse: !warehouse.includes('all') ? warehouse : [],
        sales_channel,
        date,
      })
    },
    resetForm() {
      this.isFetching = false
      this.form = {
        business: [],
        channel: [],
        warehouse: [],
        date: [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()],
      }
      this.channelList = []
      this.warehouseList = []
      this.currentBusinessId = []
      this.currentChannelList = []
      this.currentWarehouseList = []
    },
  },
}
</script>

<style lang="scss">
.export-inventory {
  .ant-form-item {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-form-item-label {
    line-height: normal;
  }
}
</style>